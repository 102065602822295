/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaginationResponse
 */
export interface PaginationResponse {
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    current: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    totalElements: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    firstItem: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    lastItem: number;
}

/**
 * Check if a given object implements the PaginationResponse interface.
 */
export function instanceOfPaginationResponse(value: object): value is PaginationResponse {
    if (!('current' in value) || value['current'] === undefined) return false;
    if (!('total' in value) || value['total'] === undefined) return false;
    if (!('totalElements' in value) || value['totalElements'] === undefined) return false;
    if (!('firstItem' in value) || value['firstItem'] === undefined) return false;
    if (!('lastItem' in value) || value['lastItem'] === undefined) return false;
    return true;
}

export function PaginationResponseFromJSON(json: any): PaginationResponse {
    return PaginationResponseFromJSONTyped(json, false);
}

export function PaginationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'current': json['current'],
        'total': json['total'],
        'totalElements': json['totalElements'],
        'firstItem': json['firstItem'],
        'lastItem': json['lastItem'],
    };
}

export function PaginationResponseToJSON(value?: PaginationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'current': value['current'],
        'total': value['total'],
        'totalElements': value['totalElements'],
        'firstItem': value['firstItem'],
        'lastItem': value['lastItem'],
    };
}

