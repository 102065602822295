/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { FreelancerProfile } from './';
import {
    FreelancerProfileFromJSON,
    FreelancerProfileFromJSONTyped,
    FreelancerProfileToJSON,
} from './';
import type { PaginationResponse } from './';
import {
    PaginationResponseFromJSON,
    PaginationResponseFromJSONTyped,
    PaginationResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaginatedProfileList
 */
export interface PaginatedProfileList {
    /**
     * 
     * @type {Array<FreelancerProfile>}
     * @memberof PaginatedProfileList
     */
    content: Array<FreelancerProfile>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof PaginatedProfileList
     */
    pagination: PaginationResponse;
}

/**
 * Check if a given object implements the PaginatedProfileList interface.
 */
export function instanceOfPaginatedProfileList(value: object): value is PaginatedProfileList {
    if (!('content' in value) || value['content'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function PaginatedProfileListFromJSON(json: any): PaginatedProfileList {
    return PaginatedProfileListFromJSONTyped(json, false);
}

export function PaginatedProfileListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedProfileList {
    if (json == null) {
        return json;
    }
    return {
        
        'content': ((json['content'] as Array<any>).map(FreelancerProfileFromJSON)),
        'pagination': PaginationResponseFromJSON(json['pagination']),
    };
}

export function PaginatedProfileListToJSON(value?: PaginatedProfileList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'content': ((value['content'] as Array<any>).map((v) => FreelancerProfileToJSON(v))),
        'pagination': PaginationResponseToJSON(value['pagination']),
    };
}

